import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import ArticleDetail, {
  ArticleData,
} from 'src/components/articles/article-detail';

const getArticles = async (id: string): Promise<ArticleData> => {
  const response = await fetch(`/articles/data/${id}.json`);

  return await response.json();
};

const ArticlePage = () => {
  const params = useParams();
  const [data, setData] = useState<ArticleData | null>();

  const load = async () => {
    const article = await getArticles(params.id as string);

    setData(article);
  };

  useEffect(() => {
    load();
  }, []);

  if (!data) {
    return;
  }

  return (
    <Container
      component="section"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
        flexWrap: 'wrap',
      }}
    >
      <ArticleDetail article={data} id={params.id as string} />
    </Container>
  );
};

export default ArticlePage;
