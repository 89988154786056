import articlesJson from './aricles-list.json';
import ArticlesListItem from 'src/components/articles/articles-list-item';
import { useArticleListStyles } from './get-styles';

const ArticlesList = () => {
  const classes = useArticleListStyles();
  const articles = articlesJson as unknown as {
    id: number;
    title: string;
    preview: string;
  }[];

  return (
    <div>
      <div className={classes.root}>
        {articles.map((article) => (
          <ArticlesListItem data={article} key={article.id} />
        ))}
      </div>
    </div>
  );
};

export default ArticlesList;
