import Modal from 'src/components/Modal';
import Response from 'src/components/Response';
import { RemoteData } from 'src/lib/remoteData';
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback, Fragment, ReactElement, useMemo, memo } from 'react';
interface RegistrationPartnerModalProps {
  partnerRequest: RemoteData<Partner[]>;
  selectedPartnerIds: number[];
  onPartnerSelect: (isChecked: boolean, partner: Partner) => void;
  handleClose: () => void;
  isOpen: boolean;
}

const NoDataHandler = () => (
  <div>
    {useIntl().formatMessage({
      id: 'no_partners',
      defaultMessage: 'There is no partners for you',
    })}
  </div>
);

const PartnersList = memo(
  ({
    partners,
    selectedPartnerIds,
    onPartnerSelect,
  }: Pick<
    RegistrationPartnerModalProps,
    'onPartnerSelect' | 'selectedPartnerIds'
  > & { partners: Partner[] }): ReactElement[] =>
    partners.map((partner, i) => (
      <Fragment key={i}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            key={i}
            sx={{
              borderTop: '1px solid #ccc',
              paddingTop: 1,
              marginTop: 1,
            }}
          >
            <Typography variant="h5" sx={{ paddingY: 1 }}>
              {partner.name}
            </Typography>
            <Stack direction="row" spacing={1}>
              {partner.marketings?.map((marketing, j) => (
                <Chip key={j} label={marketing} />
              ))}
            </Stack>
            <Box
              sx={{ paddingY: 2 }}
              dangerouslySetInnerHTML={{
                __html: partner.info.replace(/(\r)?\n/g, '<br />'),
              }}
            />
            <Link
              sx={{ paddingY: 1 }}
              href={partner.policy_url}
              target="_blank"
            >
              {partner.policy_url}
            </Link>
            <FormGroup sx={{ marginY: 1, alignItems: 'flex-start' }}>
              <FormControlLabel
                labelPlacement="start"
                label={useIntl().formatMessage({
                  id: 'disable_enable',
                  defaultMessage: 'Disable/Enable',
                })}
                sx={{ margin: 0 }}
                control={
                  <Switch
                    checked={selectedPartnerIds.includes(partner.id)}
                    onChange={(e) => onPartnerSelect(e.target.checked, partner)}
                  />
                }
              />
            </FormGroup>
          </Box>
        </Box>
      </Fragment>
    ))
);

export default function RegistrationPartnerModal(
  props: RegistrationPartnerModalProps
) {
  const {
    isOpen,
    handleClose,
    partnerRequest,
    selectedPartnerIds,
    onPartnerSelect,
  } = props;
  const intl = useIntl();

  const getPartnersList = useCallback(
    (partners: Partner[]) => (
      <>
        <Typography variant="h4">
          {intl.formatMessage({
            id: 'our_partners',
            defaultMessage: 'Our partners',
          })}
        </Typography>
        <PartnersList
          onPartnerSelect={onPartnerSelect}
          selectedPartnerIds={selectedPartnerIds}
          partners={partners}
        />
      </>
    ),
    [onPartnerSelect, selectedPartnerIds]
  );

  return (
    <Modal withClose fullWidth isOpen={isOpen} handleClose={handleClose}>
      <Response
        NotAskedView={NoDataHandler}
        EmptyView={NoDataHandler}
        request={partnerRequest}
        children={getPartnersList}
      />
    </Modal>
  );
}
