import { IntlShape, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {
  ROUTE_PAGE_COOKIE,
  ROUTE_PAGE_PRIVACY,
  ROUTE_PAGE_TERMS,
} from 'src/constants/routes';
import { PROJECT_ALIAS } from 'src/constants/app';
import useLastClick from 'src/hooks/useLastClick';
import { SAVE_CLICK_ELEMENT_NAME } from 'src/constants/enums';

const createLinks = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      id: 'footer.terms_and_conditions',
      defaultMessage: 'Terms and conditions',
    }),
    to: ROUTE_PAGE_TERMS,
  },
  {
    title: intl.formatMessage({
      id: 'footer.privacy',
      defaultMessage: 'Privacy policy',
    }),
    to: ROUTE_PAGE_PRIVACY,
  },
  {
    title: intl.formatMessage({
      id: 'footer.cookie',
      defaultMessage: 'Cookie policy',
    }),
    to: ROUTE_PAGE_COOKIE,
  },
];

const name = PROJECT_ALIAS === 'rewardis' ? 'Rewardis' : 'MySurvey';

export default function Footer() {
  const intl = useIntl();
  const { saveLast } = useLastClick();

  return (
    <Box
      component="footer"
      sx={(theme) => {
        return {
          backgroundColor: theme.custom?.footer?.background,
          maxWidth: '100%',
          width: '100%',
          marginTop: 4,
          paddingY: 2,
          flex: '0 auto',
        };
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={() => ({
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            paddingBottom: 1,
            typography: 'body1',
          })}
        >
          {createLinks(intl).map(({ title, to }, key) => (
            <Link
              component={RouterLink}
              key={key}
              onClick={() => {
                saveLast(`${SAVE_CLICK_ELEMENT_NAME.FOOTER}_${title}`);
              }}
              to={to}
              underline="none"
              sx={(theme) => ({
                color: theme.custom?.footer?.color,
                fontWeight: 300,
                marginX: 2,
                [theme.breakpoints.down('md')]: {
                  ml: 0,
                  marginTop: 1,
                },
              })}
            >
              {title}
            </Link>
          ))}
        </Box>
        <Typography
          variant="body2"
          align="center"
          sx={(theme) => ({
            color: theme.custom?.footer?.color,
            textAlign: 'center',
            marginTop: 1,
            paddingTop: 2,
            borderTop: '1px solid #353647',
            opacity: 0.5,
          })}
        >
          {intl.formatMessage({
            id: 'footer.copyright',
            defaultMessage: 'Copyright',
          })}
          {' © '}
          {new Date().getFullYear()} {name}
        </Typography>
      </Container>
    </Box>
  );
}
